import "./App.css";
import "./index.css";
import Interviewer from "./components/interviewer.js";
import WebcamCapture from "./components/WebcamCapture.js";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Timer from "./components/Timer.js";
import StartSplash from "./components/StartSplash";
import ResultsScreen from "./components/ResultsScreen";
import Typewriter from "typewriter-effect";
import AudioVisualizer from "./components/AudioVisualizer.js";
import {createClient} from "@deepgram/sdk";
import CryptoJS from "crypto-js";

class Question {
  constructor(text, type, secondsToAnswer, followUp, audioBuffer) {
    this.text = text;
    this.type = type;
    this.secondsToAnswer = secondsToAnswer;
    this.followUp = followUp;
    this.audioBuffer = audioBuffer;
  }
}

class FinalResultQuestion {
  constructor(question, transcript, videoUrl, scores, tips) {
    this.question = question;
    this.transcript = transcript;
    this.videoUrl = videoUrl;
    this.scores = scores; //array
    this.tips = tips; //array
  }
}

function App() {
  let apiEndpoint;
  let deepgramEndpoint;
  let symmetricKey;
  if (process.env.NODE_ENV === "development") {
    apiEndpoint = process.env.REACT_APP_API_ENDPOINT_LOCAL;
    deepgramEndpoint = process.env.REACT_APP_HIRELOGIC_DEEPGRAM_KEY_ENDPOINT;
    symmetricKey = process.env.REACT_APP_SYMMETRIC_KEY;
  } else {
    apiEndpoint = process.env.REACT_APP_API_ENDPOINT_PROD;
    deepgramEndpoint = process.env.REACT_APP_HIRELOGIC_DEEPGRAM_KEY_ENDPOINT;
    symmetricKey = process.env.REACT_APP_SYMMETRIC_KEY;
  }
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0);
  const [questionStack, setQuestionStack] = useState([]);
  const [showNextQuestion, setShowNextQuestion] = useState(false);
  const [speakText, setSpeakText] = useState();
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [time, setTime] = useState(0);
  const timerRef = useRef();
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const webcamRef = useRef(null);
  const chunks = useRef([]);
  const [transcript, setTranscript] = useState('');
  const [refreshTranscript, setRefreshTranscript] = useState(0);
  const refreshTranscriptRef = useRef(refreshTranscript);
  const transcriptRef = useRef(transcript);
  const [deepgramListener, setDeepgramListener] = useState(null);
  const [showSplash, setShowSplash] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [candidateEmail, setCandidateEmail] = useState(null);
  //store all FinalResultQuestion objects
  const [questionResults, setQuestionResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const [currVideo, setCurrVideo] = useState("");
  const [customQuestions, setCustomQuestions] = useState({});
  const [finalResultQuestionList, setFinalResultQuestionList] = new useState(
    []
  );
  const [progress, setProgress] = useState(1);
  const [numQuestions, setNumQuestions] = useState(1);
  const [isValidationFailed, setIsValidationFailed] = useState(false); //for setting the dropdowns on start splash to red
  const [selectedCamera, setSelectedCamera] = useState("");
  const [selectedMic, setSelectedMic] = useState("");
  const [validURL, setValidURL] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const [companyName, setCompanyName] = useState('Default');

  const handleJobDescription = (desc) => {
    setJobDescription(desc);
    console.log('Job Description in App.js:', desc);
  };

  const handleSelections = (camera, mic) => {
    setSelectedCamera(camera);
    setSelectedMic(mic);
  };

  //OpenAI generate questions
  const generateQuestion = async (fileContent) => {
    simulateLoading();
    //convert file content to json
    const jsonFile = {
      resume: fileContent,
      jobDesc: jobDescription
    };
    setLoading(true);

    //get questions from backend
    const openAiRequest = await axios
      .post(apiEndpoint + "/openai/questions", jsonFile)
      .catch(function (error) {
        if (error.response) {
          alert(error.response.data);
          setLoading(false);
        }
      });

    const deepgramKeyRequest = await axios.get(deepgramEndpoint).catch(function (error) {
      if (error.response) {
        alert(error.response.data);
        setLoading(false);
      }
    });

    const deepgramKey = CryptoJS.AES.decrypt(deepgramKeyRequest.data?.key, symmetricKey).toString(CryptoJS.enc.Utf8);
    const deepgram = createClient(deepgramKey);

    const obj = openAiRequest.data;
    console.log(obj);

    if (customQuestions[0] && customQuestions[0].question != "") {
      obj.questions = customQuestions;
    }
    if (obj.companyName) {
      setCompanyName(obj.companyName);
    }
    const position = new URL(window.location.href).searchParams.get('position');
    //put questions in stack
    for (let i = -1; i < obj.questions.length; i++) {
      let time = 90;
      let companyNameForQuestion = obj.companyName ? obj.companyName : companyName;
      let positionName = position ?? companyName;
      if (i == -1) {
        addQuestion(
          "Hi " +
          obj.name +
          ", welcome to the " + positionName + " interview! My name is Liam, I will be your interviewer today. Whenever I'm finished asking you a question, simply begin talking. I'll automatically know when you're done speaking, and we can move on - just like in a real interview! Let's practice: Could you tell me about the weather today?",
          false,
          15
        );
      } else if (i == 0) {
        if ('duration' in obj.questions[i]) {
          time = obj.questions[i].duration;
        }
        addQuestion(
          "Awesome, lets get started! " + obj.questions[i].question,
          obj.questions[i].ask_follow_up,
          time
        );
      } else {
        if ('duration' in obj.questions[i]) {
          time = obj.questions[i].duration;
        }
        addQuestion(
          obj.questions[i].question,
          obj.questions[i].ask_follow_up,
          time
        );
      }
      if (i != -1 && obj.questions[i].ask_follow_up) {
        setNumQuestions((numQuestions) => numQuestions + 1);
      }
      setNumQuestions((numQuestions) => numQuestions + 1);
    }

    // generate the audio for just the practice question
    console.log(questionStack[0].text);
    const practiceAudio = await axios.post(apiEndpoint + "/openai/firstq", {
      text: questionStack[0].text,
    });

    questionStack[0].audioBuffer = practiceAudio.data.buffer.data;

    setCandidateName(obj.name);
    setLoading(false);
    setShowNextButton(true);

    const listener = deepgram.listen.live({model: 'nova-2-conversationalai', diarize: true, punctuate: true, interim_results: true});
    setDeepgramListener(listener);
    setInterval(() => {
      listener?.keepAlive();
    }, 10000);
  };

  //begin TTS api
  const [audioUrl, setAudioUrl] = useState(null);

  //start recording
  const startRecording = () => {
    console.log("started recording");
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: {facingMode: 'user'} })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        const questionChunks = [];

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            questionChunks.push(e.data);
          }
        };

        setMediaRecorder(recorder);
        recorder.start();
        setRecording(true);
        chunks.current = questionChunks; // Set the chunks reference
      })
      .catch((error) => console.error("Error accessing media devices:", error));
  };

  const startListening = () => {
    console.log('started listening');
    if(!audioRecorder) {
      navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (e) => {
          if (e.data.size > 0 && deepgramListener.getReadyState() === 1) {
            deepgramListener.send(e.data);
          }
        };

        deepgramListener.on('Results', (data) => {
          const response = data.channel.alternatives[0].transcript;
          const currentTranscript = transcriptRef.current;
          if (response !== "" && data.is_final) {
            if(currentTranscript === '') {
              setTranscript(response);
            } else {
              setTranscript(currentTranscript + ' ' + response);
            }
          }
          else if(response !== "" && !data.is_final){
            // refresh transcript, so it would not close before speaking is finished
            setRefreshTranscript(Date.now());
          }
        });

        recorder.start(500);
        setAudioRecorder(recorder);
        setAudioStream(stream);
      });
    } else {
      // unmute
      audioStream?.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
    }
  }

  const stopListening = () => {
    // mute
    audioStream?.getAudioTracks().forEach((track) => {
      track.enabled = false;
    });
  }

  const resetTranscript = () => {
    setTranscript('');
    transcriptRef.current = '';
  }
  // ...

  const stopRecording = () => {
    console.log("stopped recording");
    return new Promise((resolve) => {
      if (mediaRecorder) {
        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks.current, { type: "video/webm" });
          const url = URL.createObjectURL(blob);
          console.log("Video URL:", url);
          setCurrVideo(url);
          resolve(url); // resolve with the URL
        };
        mediaRecorder.stop();
        setRecording(false);
      }
    });
  };

  function handleShowSplash() {
    if (!selectedCamera || !selectedMic) {
      setIsValidationFailed(true);
    } else {
      setShowSplash(false);
    }
  }

  const handleEmailSubmit = (submittedEmail) => {
    setCandidateEmail(submittedEmail);
  };


  const onSelectChange = () => {
    setIsValidationFailed(false);
  };

  function addQuestion(question, askFollowUp, time) {
    const newQuestion = new Question(
      question,
      "basic",
      time,
      askFollowUp,
      null
    );

    questionStack.push(newQuestion);
  }

  // Get the current question based on the index
  const currQuestion = questionStack[currQuestionIndex];

  //generate tts audio
  function TTS() {
    const data = {
      text: speakText,
      model_id: "eleven_monolingual_v1",
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.5,
      },
    };

    const playAudio = async () => {
      //convert question to json

      // convert buffer to blob
      const blob = new Blob(
        [new Uint8Array(questionStack[currQuestionIndex].audioBuffer)],
        { type: "audio/mp3" }
      );
      console.log("blob: " + blob);
      console.log(blob);
      // put blob in URL
      const url = URL.createObjectURL(blob);
      console.log("\nurl:");
      console.log(url);
      // set audio url
      setAudioUrl(url);
    };
    playAudio();
  }

  //end TTS stuff

  let timeout;

  useEffect(() => {
    if (showNextQuestion && currQuestion) {
      setSpeakText(currQuestion.text);
    } else {
      // Clear the timeout if we exit early
      clearTimeout(timeout);
    }
  }, [currQuestionIndex, showNextQuestion, currQuestion]);

  /*
  MAIN START INTERVIEW FUNCTION
  */
  function startInterview() {
    startRecording();
    // startListening();
    setShowNextQuestion(true);
    setInterviewStarted(true);
  }

  useEffect(() => {
    if (currQuestionIndex < questionStack.length && interviewStarted) {
      startInterview();
    } else {
      setInterviewStarted(false);
      stopListening();
    }
  }, [currQuestionIndex, questionStack]);

  const handleDoneAnswering = async () => {
    stopListening();
    const videoUrl = await stopRecording();
    console.log(transcript);
    if (currQuestionIndex != 0) {
      questionResults.push(
        new FinalResultQuestion(
          currQuestion.text,
          transcript,
          videoUrl,
          null,
          null
        )
      );
    }

    setLoading(true);

    //if there is a follow up || there is another question in the stack
    if (currQuestion.followUp || currQuestionIndex + 1 < questionStack.length) {
      const jsonTranscript = {
        lastQuestion: currQuestion.text,
        response: transcript,
      };

      console.log("\nFollow up?");
      console.log(currQuestion.followUp);
      if (!currQuestion.followUp) {
        //set next question
        jsonTranscript.nextQuestion = questionStack[currQuestionIndex + 1].text;
      }

      // send request to play.ht
      console.log("\njsonTranscript");
      console.log(jsonTranscript);

      let justAudio = {};
      let questionAndAudio = {};
      if (currQuestionIndex === 0) {
        justAudio = await axios.post(apiEndpoint + "/openai/firstq", {
          text: questionStack[1].text,
        });
      } else {
        questionAndAudio = await axios.post(
          apiEndpoint + "/openai/speak",
          jsonTranscript
        );
      }


      // if this is not follow up
      if (jsonTranscript.nextQuestion !== undefined) {
        // if about me question
        if (currQuestionIndex === 0) {
          questionStack[currQuestionIndex + 1].audioBuffer =
            justAudio.data.buffer.data;
        } else {
          //update the next question in stack to have remark and original question
          questionStack[currQuestionIndex + 1].text =
            questionAndAudio.data.question;
          // assign the audio buffer
          questionStack[currQuestionIndex + 1].audioBuffer =
            questionAndAudio.data.buffer.data;
        }
      } else {
        const followUpQuestion = new Question(
          questionAndAudio.data.question,
          "basic",
          90,
          false,
          questionAndAudio.data.buffer.data
        );
        questionStack.splice(currQuestionIndex + 1, 0, followUpQuestion);
      }
    }

    //if that was the last question:
    else {
      setLoadingResults(true);
      const jsonData = {};

      const getBlobDataFromUrl = async (url) => {
        const response = await fetch(url);
        const blobData = await response.blob();
        return blobData;
      };

      for (let i = 0; i < questionResults.length; i++) {
        const questionResult = questionResults[i];
        const questionData = {
          question: questionResult.question,
          transcript: questionResult.transcript,
        };
        jsonData[i + 1] = questionData;
      }

      const jsonString = JSON.stringify(jsonData);
      console.log("this is the json");
      console.log(jsonString);

      //parse url and determine addToDB, reruiter email, positon
      const currentUrl = window.location.href;
      const urlParams = new URL(currentUrl).searchParams;
      //*deprecated i think? start
      let addToDB = false;
      let recruiterEmail = "";
      let position = "";
      let stage = 'dev';

      if (urlParams.has('recruiterEmail') && urlParams.has('position')) {
        recruiterEmail = urlParams.get('recruiterEmail');
        position = urlParams.get('position');
        // let uniqueNumber = urlParams.get('uniqueNumber');
        addToDB = true;
      }
      if(urlParams.has('stage')) {
        stage = urlParams.get('stage');
      }
      //end
      const JSONData = {
        jsonData,
        addToDB: validURL,
        candidateEmail: candidateEmail,
        recruiterEmail: recruiterEmail,
        position: position,
        candidateName: candidateName,
        link: currentUrl,
        stage: stage,
      };

      //form data to send to backend
      const formData = new FormData();
      formData.append('json', JSON.stringify(JSONData));

      // Loop through questionResults to append video data
      for (let i = 0; i < questionResults.length; i++) {
        const videoUrl = questionResults[i].videoUrl;
        const videoBlob = await getBlobDataFromUrl(videoUrl);
        formData.append(`video${i + 1}`, videoBlob, `video${i + 1}.mp4`);
      }

      const response = await axios.post(apiEndpoint + "/openai/results", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("scores and tips:");
      console.log(response.data);

      for (const key in response.data) {
        console.log("key:" + key);
        const item = response.data[key];
        console.log("item: " + item);
        questionResults[key * 1 - 1].scores = item.scores;
        questionResults[key * 1 - 1].tips = item.tips;
      }

      for (let i = 0; i < questionResults.length; i++) {
        const item = questionResults[i];
        const questionResult = new FinalResultQuestion(
          item.question,
          item.transcript,
          item.videoUrl,
          item.scores,
          item.tips
        );
        finalResultQuestionList.push(questionResult);
      }
    }

    resetTranscript();
    clearTimeout(timeout);
    setCurrQuestionIndex(currQuestionIndex + 1);
    setShowNextQuestion(false);
    setTime(currQuestion ? currQuestion.secondsToAnswer : 0); // Set time based on the current question
    if (timerRef.current) {
      timerRef.current.resetTimer();
    }
    setLoading(false);
    setProgress(progress + 1);
  };

  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    if (speakText) {
      TTS();
      //play audio here
    }
  }, [speakText]);

  useEffect(() => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.playbackRate = 1.17;

      // Event handler for when audio stops playing
      audio.onended = () => {
        setSpeaking(false);
        if (timerRef.current) {
          startListening();
          timerRef.current.startTimer();
        }
      };

      // Ensure that the audio is loaded before attempting to play it
      audio.addEventListener("canplaythrough", () => {
        setSpeaking(true);
        stopListening();
        audio.play();
      });
    }
  }, [audioUrl]);

  const [fakeLoadingPercentage, setFakeLoadingPercentage] = useState(0);

  // Function to simulate loading progress
  const simulateLoading = () => {
    let percentage = 0;
    const intervalId = setInterval(() => {
      // Introduce randomness in percentage increments
      const randomIncrement = Math.floor(Math.random() * 10) + 5; // Random number between 5 and 20
      percentage += randomIncrement;

      // Ensure percentage doesn't go beyond 98
      if (percentage >= 98) {
        percentage = 98;
      }

      setFakeLoadingPercentage(percentage);

      // Stop when reaching 98
      if (percentage >= 98) {
        clearInterval(intervalId);

        // Simulate additional loading (you can replace this with your actual loading logic)
        setTimeout(() => { }, 800); // Adjust the delay based on your desired loading completion time
      }
    }, 1000); // Adjust the interval based on your desired loading speed
  };

  //auto submit answer
  /////////////////////////////////////////////
  const [isUserSpeaking, setIsUserSpeaking] = useState(false);
  const silenceTimer = useRef(null);

  useEffect(() => {
    if (transcript && !loading) {
      setIsUserSpeaking(true);
      clearTimeout(silenceTimer.current);
      transcriptRef.current = transcript;
      refreshTranscriptRef.current = refreshTranscript;
      silenceTimer.current = setTimeout(() => {
        setIsUserSpeaking(false);
        handleDoneAnswering();
      }, 2800); // DOMINIK changed from 3500 to 2000 for testing. Original comment: 3.5 sec of silence untill it auto submits
      // Daniel changed to 2800 in deepgram, because it reacts a bit differently.
    }
  }, [transcript, refreshTranscript]);

  useEffect(() => {
    if (!isUserSpeaking) {
      handleDoneAnswering();
    }
  }, [isUserSpeaking]);
  ///////////////////////////////////

  const typewriterKey = currQuestion ? currQuestion.text : "no-question";

  return (
    <div class="App">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {showSplash ? (
        <div>
          <StartSplash
            onJobDescriptionSelect={handleJobDescription}
            onFileSelect={generateQuestion}
            onSelections={handleSelections}
            isValidationFailed={isValidationFailed}
            onSelectChange={onSelectChange}
            onEmailSubmit={handleEmailSubmit}
            validURL={validURL}
            setValidURL={setValidURL}
            setCustomQuestions={setCustomQuestions}
          />

          <div class="container">
            <div class="row">
              <div class="r-al">
                {showNextButton ? (
                  // show button
                  <button
                    type="button"
                    class="btn btn-custom-start"
                    onClick={handleShowSplash}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2rem"
                      height="2rem"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </button>
                ) : (
                  <div>
                    {loading ? (
                      <div>
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                        <div>{fakeLoadingPercentage}%</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <img
            src="LiamLogo.png"
            style={{
              width: "15rem",
              position: "relative",
              top: "0rem",
              left: "1rem",
            }}
          />

          {currQuestionIndex < questionStack.length ? (
            //everything else
            <div class="body">
              <div class="center-items">
                <div class="container-fluid text-center">
                  <div class="row vert">
                    <div class="col-6">
                      <div class="card border-0">
                        <Interviewer
                          speaking={speaking}
                          style="height: 100%;"
                        />
                        <div class="card-img-overlay h-100 d-flex align-items-end cardOverlay">
                          <div class="transparent-box">
                            <h6>Liam</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" width="18rem">
                      <div class="card shortCard text-left border-0">
                        <WebcamCapture></WebcamCapture>
                        <div class="card-img-overlay h-100 d-flex align-items-end cardOverlayUser">
                          <div class="transparent-box">
                            <h6>{candidateName}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currQuestion && (
                <div>
                  {interviewStarted ? (
                    <div class="questionBox">
                      <div class="container">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="question col-10">
                                <Typewriter
                                  options={{
                                    delay: 20,
                                  }}
                                  key={typewriterKey}
                                  onInit={(typewriter) => {
                                    typewriter
                                      .typeString(currQuestion.text)
                                      .start();
                                  }}
                                  onDestroy={(typewriter) => {
                                    typewriter.stop();
                                  }}
                                />
                              </div>
                              <div class="col-2 d-flex align-items-end justify-content-end">
                                {interviewStarted ? (
                                  <div>
                                    {loading ? (
                                      <div
                                        class="spinner-border bumper"
                                        role="status"
                                      >
                                        <span class="sr-only"></span>
                                      </div>
                                    ) : (
                                      <div>
                                        <div>
                                          <div>
                                            <AudioVisualizer></AudioVisualizer>
                                            {/* timer */}
                                            <div class="timer">
                                              <Timer
                                                ref={timerRef}
                                                initialTime={
                                                  currQuestion
                                                    ? currQuestion.secondsToAnswer
                                                    : 0
                                                } // Set initialTime based on the current question
                                                onTimerEnd={handleDoneAnswering} // Pass the callback function
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>

                              {loadingResults ? (
                                <div
                                  class="animate-flicker fixed-bottom mb-4 mx-auto text-center"
                                  style={{
                                    fontSize: "150%",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Processing Results
                                  <div class="processing">
                                    This will take a minute
                                  </div>
                                </div>
                              ) : (
                                <div
                                  class="progress fixed-bottom mb-4 w-50 mx-auto px-0"
                                  style={{ height: ".4%" }}
                                >
                                  <div
                                    class="progress-bar bg-success"
                                    role="progressbar"
                                    style={{
                                      width: `${(progress / numQuestions) * 100
                                        }%`,
                                      height: "100%",
                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-dark " // Add the CSS class "bold-button"
                      onClick={startInterview}
                    >
                      Start Interview
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            //thank you screen
            <div>
              <ResultsScreen
                finalResultQuestionList={finalResultQuestionList}
                validURL={validURL}
              ></ResultsScreen>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
